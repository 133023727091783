<template>
	<span>
		<span @click="onShowModal">
			<slot>
				<a-button type="primary">更换使用政策</a-button>
			</slot>
		</span>
		<a-modal v-model:visible="visible" title="使用政策列表" width="700px">
			<template #footer>
				<a-button @click="visible = false">关闭</a-button>
			</template>
			<a-spin :spinning="loading">
				<a-form ref="form" name="form" :model="formState" @finish="onSearch">
					<a-row>
						<a-form-item name="name" label="使用政策名称">
							<a-input v-model:value="formState.name" placeholder="请输入使用政策名称"></a-input>
						</a-form-item>
					</a-row>
					
					<a-row>
						<a-col :span="24" style="text-align: right;">
							<a-button type="primary" html-type="submit">搜索</a-button>
							<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>
				
				<a-table rowKey="id" style="margin-top: 10px;" :columns="columns" :dataSource="list" :pagination="pagination">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'action'">
							<a-button type="link" :disabled="record.id === rulesId" @click="onSelect(record)">选择</a-button>
						</template>
					</template>
				</a-table>
			</a-spin>
		</a-modal>
	</span>
</template>

<script>
	import { getCouponPolicyList, replaceCouponPolicy } from '@/service/modules/rules.js';
	export default {
		props: {
			id: {
				type: Number,
				default: 0
			},
			rulesId: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				formState: {},
				searchData: {},
				list: [],
				columns: [{
					title: '使用政策名称',
					dataIndex: 'name'
				}, {
					title: '规则数量',
					dataIndex: 'num'
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		methods: {
			onShowModal() {
				this.visible = true;
				this.$nextTick(() => {
					this.reset();
				})
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState))
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCouponPolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSelect(record) {
				this.$confirm({
					title: '提示',
					content: '确定更换为此使用政策吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await replaceCouponPolicy({
								id: this.id,
								couponPolicyId: record.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('更换使用政策成功！');
								this.$emit('onRef');
								this.visible = false;
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style>
</style>
